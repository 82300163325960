import * as React from "react"
import { createStorefrontApiClient } from '@shopify/storefront-api-client';

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: '2024-04',
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
});

const defaultValues = {
  cart: {
    id: '',
    checkoutUrl: '',
    lines: [],
    totalQuantity: 0,
    cost: {
      subtotalAmount: { amount: '0.0', currencyCode: 'USD' },
      totalTaxAmount: { amount: '0.0', currencyCode: 'USD' },
      totalAmount: { amount: '0.0', currencyCode: 'USD' }
    }  
  },
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_cart_id`

export const StoreProvider = ({ children }) => {
  const [cart, setCart] = React.useState(defaultValues.cart)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

  React.useEffect(() => {
    const initializeCart = async () => {
      const existingCartID = isBrowser ? localStorage.getItem(localStorageKey) : null;
  
      if (existingCartID) {
        try {
          const { data } = await client.request(
            `query GetCart($id: ID!) {
              cart(id: $id) {
                id
                checkoutUrl
                totalQuantity
                lines(first: 100) {
                  nodes {
                    id
                    quantity
                    merchandise {
                      ... on ProductVariant {
                        id
                        title
                        image {
                          src
                          width
                          height
                          altText
                        }
                        price {
                          amount
                          currencyCode
                        }
                      }
                    }
                  }
                }
                cost {
                  subtotalAmount {
                    amount
                    currencyCode
                  }
                  totalTaxAmount {
                    amount
                    currencyCode
                  }
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
              }
            }`,
            { variables: { id: existingCartID } }
          );
          if (data.cart) return setCart(data.cart);
        } catch (error) {
          localStorage.removeItem(localStorageKey);
        }
      }
  
      const { data } = await client.request(
        `mutation CreateCart {
          cartCreate {
            cart {
              id
              checkoutUrl
              totalQuantity
            }
          }
        }`
      );
      const newCart = data.cartCreate.cart;
      localStorage.setItem(localStorageKey, newCart.id);
      setCart(newCart);
    };
  
    initializeCart();
  }, []);  

  const addVariantToCart = async (variantId, quantity) => {
    setLoading(true);
    
    const { data } = await client.request(
      `mutation AddToCart($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            id
            checkoutUrl
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalAmount {
                amount
                currencyCode
              }
            }
            lines(first: 100) {
              nodes {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    price {
                      amount
                      currencyCode
                    }
                    image {
                      src
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }`,  
      {
        variables: {
          cartId: cart.id,
          lines: [{
            merchandiseId: variantId,
            quantity: parseInt(quantity, 10)
          }]
        }
      }
    );
  
    setCart(data.cartLinesAdd.cart);
    setLoading(false);
    setDidJustAddToCart(true);
    setTimeout(() => setDidJustAddToCart(false), 3000);
  };  

  const removeLineItem = async (lineItemID) => {
    setLoading(true);
  
    const { data } = await client.request(
      `mutation RemoveLineItem($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            checkoutUrl
            totalQuantity
            lines(first: 100) {
              nodes {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    price {
                      amount
                      currencyCode
                    }
                    image {
                      src
                      altText
                    }
                  }
                }
              }
            }
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }`,
      {
        variables: {
          cartId: cart.id,
          lineIds: [lineItemID]
        }
      }
    );
  
    setCart(data.cartLinesRemove.cart);
    setLoading(false);
  };  

  const updateLineItem = async (lineItemID, quantity) => {
    setLoading(true);
  
    const { data } = await client.request(
      `mutation UpdateLineItem($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            id
            checkoutUrl
            totalQuantity
            lines(first: 100) {
              nodes {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    price {
                      amount
                      currencyCode
                    }
                    image {
                      src
                      altText
                    }
                  }
                }
              }
            }
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }`,
      {
        variables: {
          cartId: cart.id,
          lines: [{
            id: lineItemID,
            quantity: parseInt(quantity, 10)
          }]
        }
      }
    );
  
    setCart(data.cartLinesUpdate.cart);
    setLoading(false);
  };  

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        cart,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        loading,
        didJustAddToCart,
        }}
    >
      {children}
    </StoreContext.Provider>
  )
}
